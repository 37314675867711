<template>
  <div>
    <b-table striped small hover :items="scriptoriumsData">
      <template #cell(id)="data">
        <tdColUUID :value="data.item.id" />
      </template>
      <template #cell(action)="data">
        <div class="text-right">
          <router-link :to="{ name: 'ScriptoriumDetails', params: {
            id: data.item.action.id
          }}">
            <b-icon icon="link45deg"></b-icon>
          </router-link>
          <a
            href="#"
            v-if="editMode"
            @click="editRow($event, data.item.action.scriptorium)"
            ><b-icon icon="pencil"></b-icon
          ></a>
          <a href="#" v-if="editMode" @click="deleteRow($event, data.item.action.id)"><b-icon icon="trash"></b-icon></a>
        </div>
      </template>
    </b-table>
    <b-col sm="4" md="3" v-if="showTotalLable">
      Total {{ scriptoriumsData.length }} {{ scriptoriumsData.length === 1 ? 'entry' : "entries" }} in table
    </b-col>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  import tdColUUID from '@/components/tdColUUID.vue'

  export default {
    name: 'ScriptoriumTable',
    props: ['scriptoriums', 'editMode', 'showTotal'],
    data(){
      return {
        showTotalLable: this.showTotal ? this.showTotal : false
      }
    },
    computed: {
      scriptoriumsData(){
        return this.scriptoriums.map((scriptorium, index) => {
          scriptorium['_index'] = index;
          return {
            id: scriptorium.id,
            abbreviation: scriptorium.abbreviation,
            name: scriptorium.name,
            comment: scriptorium.comment,
            confidenceScriptorium: scriptorium.confidenceScriptorium,
            action: {
              scriptorium: scriptorium,
              id: scriptorium.id
            }
          }
        })
      }
    },
    components: {
      tdColUUID
    },
    methods: {
      deleteRow(event, scriptoriumId){
        event.preventDefault()
        // this.$store.dispatch('addNotification', {
        //   type: 'warning',
        //   text: `Scriptorium deleted`
        // })
        this.$emit('delete', scriptoriumId)
      },
      editRow(event, scriptorium) {
        event.preventDefault();
        this.$emit("edit", scriptorium);
      },
    }
  }
</script>
