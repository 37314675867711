<template>
  <div class="scriptoriums" id="select-scriptorium">
    <Select2 @select="select($event)" :settings="selectConfig" ref="select2" tabindex="0" />
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import config from '@/config.js'

  export default {
    name: 'ScriptoriumSelect',
    props: ['exclude', 'scriptorium', 'allowClear'],
    components: {
      Select2
    },
    mounted() {
      if (this.scriptorium){
        this.$refs.select2.select2.append(
          `<option selected value="${this.scriptorium.id}">${this.scriptorium.name}</option>`
        ).trigger('change')
      }
    },
    computed: {
      selectConfig(){
        return {
          placeholder: "Select a scriptorium",
          allowClear: this.allowClear == false ? false : true,
          width: '100%',
          dropdownParent: "#select-scriptorium",
          ajax: {
            delay: 250,
            url: config.apiUrl + '/scriptoriums/autocomplete',
            headers: config.apiHeaders,
            data: (params) => {
              return {
                query: params.term
              }
            },
            processResults: (data) => {
              return {
                results: data.map(scriptorium => {
                  return {
                    id: scriptorium.id,
                    text: `${scriptorium.abbreviation} | ${scriptorium.name}`,
                    scriptorium: scriptorium
                  }
                })
              }
            }
          }
        }
      }
    },
    methods: {
      select(event){
        this.$emit('select', event.scriptorium)
      }
    }
  }
</script>
