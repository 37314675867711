<template>
  <div>
    <b-table striped small hover :items="authorhistsData">
      <template #cell(sigleLegam)="data">
        <router-link
          :to="{name: 'HistoricalAuthorDetails', params: {
            slug: data.item.action.sigleLegamNormalized,
            id: data.item.action.id
          }}"
          v-html="data.item.sigleLegam"
        >
        </router-link>
      </template>
      <template #cell(id)="data">
        <tdColUUID :value="data.item.id" />
      </template>
      <template #cell(action)="data">
        <div class="text-right">
          <router-link :to="{ name: 'HistoricalAuthorDetails', params: {
            slug: data.item.action.sigleLegamNormalized,
            id: data.item.action.id
          }}">
            <b-icon icon="link45deg"></b-icon>
          </router-link>
          <a
            href="#"
            v-if="editMode && showLinkAuthorhist"
            @click="editRow($event, data.item.action.authorhist)"
            ><b-icon icon="pencil"></b-icon
          ></a>
          <a href="#" v-if="editMode" @click="deleteRow($event, data.item.action.id)"><b-icon icon="trash"></b-icon></a>
        </div>
      </template>
    </b-table>
    <b-col sm="4" md="3" v-if="showTotalLable">
      Total {{ authorhistsData.length }} {{ authorhistsData.length === 1 ? 'entry' : "entries" }} in table
    </b-col>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  import tdColUUID from '@/components/tdColUUID.vue'

  export default {
    name: 'AuthorhistTable',
    props: ['authorhists', 'editMode', 'showTotal', 'showLinkAuthorhist'],
    data(){
      return {
        showTotalLable: this.showTotal ? this.showTotal : false
      }
    },
    computed: {
      authorhistsData(){
        return this.authorhists.map((authorhist, index) => {
          authorhist['_index'] = index;
          return {
            sigleLegam: authorhist.sigleLegam,
            id: authorhist.id,
            ...(this.showLinkAuthorhist && { comment: authorhist.comment }),
            datesOfLife: authorhist.datesOfLife,
            datesOfActivity: authorhist.datesOfActivity,
            commentGeneral: authorhist.commentGeneral,
            action: {
              sigleLegamNormalized: authorhist.sigleLegamNormalized,
              authorhist: authorhist,
              id: authorhist.id
            }
          }
        })
      }
    },
    components: {
      tdColUUID
    },
    methods: {
      deleteRow(event, authorhistId){
        event.preventDefault()
        // this.$store.dispatch('addNotification', {
        //   type: 'warning',
        //   text: `Authorhist deleted`
        // })
        this.$emit('delete', authorhistId)
      },
      editRow(event, authorhist) {
        event.preventDefault();
        this.$emit("edit", authorhist);
      },
    }
  }
</script>
