var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table',{attrs:{"striped":"","small":"","hover":"","items":_vm.authorhistsData},scopedSlots:_vm._u([{key:"cell(sigleLegam)",fn:function(data){return [_c('router-link',{attrs:{"to":{name: 'HistoricalAuthorDetails', params: {
          slug: data.item.action.sigleLegamNormalized,
          id: data.item.action.id
        }}},domProps:{"innerHTML":_vm._s(data.item.sigleLegam)}})]}},{key:"cell(id)",fn:function(data){return [_c('tdColUUID',{attrs:{"value":data.item.id}})]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{ name: 'HistoricalAuthorDetails', params: {
          slug: data.item.action.sigleLegamNormalized,
          id: data.item.action.id
        }}}},[_c('b-icon',{attrs:{"icon":"link45deg"}})],1),(_vm.editMode && _vm.showLinkAuthorhist)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.editRow($event, data.item.action.authorhist)}}},[_c('b-icon',{attrs:{"icon":"pencil"}})],1):_vm._e(),(_vm.editMode)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.deleteRow($event, data.item.action.id)}}},[_c('b-icon',{attrs:{"icon":"trash"}})],1):_vm._e()],1)]}}])}),(_vm.showTotalLable)?_c('b-col',{attrs:{"sm":"4","md":"3"}},[_vm._v(" Total "+_vm._s(_vm.authorhistsData.length)+" "+_vm._s(_vm.authorhistsData.length === 1 ? 'entry' : "entries")+" in table ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }