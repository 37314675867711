<template>
  <div class="locations" id="select-location">
    <Select2 @select="select($event)" v-model="selectedItems" :settings="selectConfig" ref="select2" tabindex="0" />
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import config from '@/config.js'
  import $ from 'jquery'

  export default {
    name: 'LocationSelectMulti',
    props: {
      'exclude': {default: () => []},
      'selected': {default: () => []}
    },
    components: {
      Select2
    },
    data(){
      return {
        selectedItems: this.selected.map(item => item.id),
        selectedObjs: this.selected,
        lastSearch: '',
        newIdIndex: -1
      }
    },
    mounted() {
      if (this.selected){
        this.selected.forEach(location => {
          this.$refs.select2.select2.append(
            `<option selected value="${location.id}">${location.display}</option>`
          ).trigger('change')
        })
      }
    },
    computed: {
      selectConfig(){
        return {
          placeholder: "Select a location",
          allowClear: true,
          templateResult: this.formatOption,
          templateSelection: this.formatOption,
          width: '100%',
          dropdownParent: "#select-location",
          multiple: true,
          ajax: {
            delay: 250,
            url: config.apiUrl + '/locations/autocomplete',
            headers: config.apiHeaders,
            data: (params) => {
              this.lastSearch = params.term
              return {
                query: params.term
              }
            },
            processResults: (data) => {
              // data.push({
              //   id: this.newIdIndex,
              //   titleName: `Create new location: ${this.lastSearch}`,
              //   title: {
              //     "id": this.newIdIndex.toString(),
              //     "titleName": this.lastSearch
              //   }
              // })
              return {
                results: data.map(location => {
                  return {
                    id: location.id,
                    text: location.display,
                    location: location
                  }
                })
              }
            }
          }
        }
      }
    },
    methods: {
      select(event){
        if (event.selected === true){
          if (event.location.id < 0){
            this.newIdIndex--
            this.selectedObjs.push(event.location.location)
          }
          else {
            this.selectedObjs.push(event.location)
          }
        }
        else if (event.selected == false && event.id) {
          this.selectedObjs = this.selectedObjs.filter(location => location.id != event.id)
        }
        this.$emit('select', this.selectedObjs)
      },
      formatOption (item) {
        let text = item.text.trim() ? item.text : item.id
        return $(`<span>${text}</span>`)
      }
    }
  }
</script>
