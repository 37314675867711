<template>
  <div class="reviews">
    <b-container fluid="xxl">
      <b-row>
        <b-col>
          <ScriptoriumSelect
            @select="selectScriptorium"
            :scriptorium="scriptoriumObj"
            :allowClear="false"
            class="mb-3"
          />

          <b-form-group
            label="confidenceScriptorium:"
            label-for="confidenceScriptorium"
          >
            <b-form-select v-model="confidenceScriptorium">
              <b-form-select-option value="certain">certain</b-form-select-option>
              <b-form-select-option value="uncertain">uncertain</b-form-select-option>
              <b-form-select-option value="undefined">undefined</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ScriptoriumSelect from "@/components/Bibliography/ScriptoriumSelect.vue";
import HelpStore from '@/store/helpstore.js'

export default {
  name: "ScriptoriumAutocompleteManuscriptText",
  props: ["exclude", "scriptorium"],
  data() {
    return {
      query: "",
      timer: null,
      scriptoriumObj: this.scriptorium,
      confidenceScriptorium: this.scriptorium ? this.scriptorium.confidenceScriptorium : "",
      index: this.scriptorium ? this.scriptorium._index : null,
    };
  },
  components: {
    ScriptoriumSelect,
  },
  methods: {
    selectScriptorium(scriptorium) {
      this.scriptoriumObj = scriptorium;
    },
  },
  updated(){
    this.scriptoriumObj['confidenceScriptorium'] = this.confidenceScriptorium;
    this.scriptoriumObj['_index'] = this.index;
    HelpStore.item = this.scriptoriumObj
  }
};
</script>
